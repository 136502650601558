import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import App from "./App";
import { SnackbarProvider } from "./context/SnackbarContext";
import ErrorBoundary from "./error boundary/ErrorBoundary";
import "./index.css";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <HashRouter>
        <Provider store={store}>
          <SnackbarProvider>
            <App />
          </SnackbarProvider>
        </Provider>
      </HashRouter>
    </ErrorBoundary>
  </React.StrictMode>
);
reportWebVitals();
