import { createSlice } from "@reduxjs/toolkit";
import { fetchRecentJobList } from "./recentJobListActions";

const initialState = {
    recentJobs: [],
    isRecentJobsLoading: 'idle',
    error: null
};

export const recentJobListSlice = createSlice({
    name: 'recentJobs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRecentJobList.pending, (state) => {
                state.isRecentJobsLoading = 'loading';
                state.error = null;
            })
            .addCase(fetchRecentJobList.fulfilled, (state, action) => {
                state.isRecentJobsLoading = 'success';
                state.recentJobs = action.payload;
            })
            .addCase(fetchRecentJobList.rejected, (state, action) => {
                state.recentJobs = [];
                state.isRecentJobsLoading = 'failed';
                state.error = action.payload;
            });
    }
});

export default recentJobListSlice.reducer;
