import { createAsyncThunk } from "@reduxjs/toolkit";
import skillsBasedJobsServices from "../../../services/skillsBasedJobsServices";

export const fetchJobList = createAsyncThunk(
    "jobs/fetchJobList",
    async (payload, { rejectWithValue }) => {
        try {
            const res = await skillsBasedJobsServices.post(
                `/skill/api/v1/search-jobs`, payload
            );
            return res?.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);
