import React, { Fragment, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Preloader from "../components/atoms/preloader/Preloader";
import routes from "./routesConfig";
import ScrollToTop from "../components/layout/ScrollToTop/ScrollToTop";

const renderedRoutes = (routes) => {
  return routes.map((route, index) => {
    const Layout = route.layout || Fragment;
    return (
      <Route
        key={index}
        path={route.path}
        exact={route?.exact}
        element={
          <Layout>
            <Suspense fallback={<Preloader />}>
              <route.element />
            </Suspense>
          </Layout>
        }
      >
        {route.children && renderedRoutes(route.children)}
      </Route>
    );
  });
};

const AppRouter = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>{renderedRoutes(routes)}</Routes>;
    </>
  );
};

export default AppRouter;
