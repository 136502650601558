import React, { memo } from "react";
import { useSelector } from "react-redux";
import SomethingWentWrong from "../../../error boundary/something wrong page/SomethingWentWrong";
import messages from "../../../utils/models/messages";
import Footer from "../footer/Footer";
import Header from "../header/Header";

const MainLayout = ({ children }) => {
  const { isJobLoading } = useSelector((state) => state.job);
  const { isJobListLoading } = useSelector((state) => state.jobs);

  const isError = isJobLoading === "failed" || isJobListLoading === "failed";

  return (
    <>
      {isError ? (
        <SomethingWentWrong message={messages?.GET?.error} />
      ) : (
        <div className="page-wrapper">
          <Header />
          <main>{children}</main>
          <Footer />
        </div>
      )}
    </>
  );
};

export default memo(MainLayout);
