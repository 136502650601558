import axios from "axios";
import { getApiBaseURL } from "../config/apiConfig";
import {
    getRefreshToken,
    setAccessToken,
    setRefreshToken,
} from "./localstorages";

const skillsBasedJobsServices = axios.create({
    baseURL: getApiBaseURL(),
    headers: {
        "Content-Type": "application/json",
    },
});

/* INTERCEPTORS TO ATTACH AUTH TOKEN */
skillsBasedJobsServices.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("authToken");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

/* RESPONSE INTERCEPTOR WITH TOKEN REFRESH LOGIC */
skillsBasedJobsServices.interceptors.response.use(
    (response) => response,
    async (error) => {
        /* UNCOMMENT IT ONCE THE API FOR REFRESH TOKEN IS READY */
        // const originalRequest = error.config;

        // if (error?.response?.status === 401 && !originalRequest._retry) {
        //     originalRequest._retry = true;
        //     try {
        //         const { access_token } = await refreshAccessToken();

        //         axios.defaults.headers.common["Authorization"] =
        //             "Bearer " + access_token;
        //         originalRequest.headers["Authorization"] = "Bearer " + access_token;
        //         return skillsBasedJobsServices(originalRequest);
        //     } catch (refreshError) {
        //         console.error("Token refresh failed:", refreshError);
        //         return Promise.reject(refreshError);
        //     }
        // }

        /* IF RESPONSE STATUS IS 401 REDIRECT TO HOME SCREEN */
        if (error?.response?.status === 401) {
            localStorage.clear();
            window.location.href = '/';
        }

        return Promise.reject(error);
    }
);

/* REFRESH ACCESS TOKEN FUNCTION */
export const refreshAccessToken = async () => {
    try {
        /* REFRESH TOKEN API CALL */
        const response = await skillsBasedJobsServices.post("/auth/refresh", null, {
            headers: {
                Authorization: `Bearer ${getRefreshToken()}`,
            },
        });

        setAccessToken(response.data.access_token);
        setRefreshToken(response.data.refresh_token);

        return {
            access_token: response.data.access_token,
            refresh_token: response.data.refresh_token,
        };
    } catch (error) {
        console.error("Error refreshing access token:", error);
        throw error;
    }
};

export const getLocationOptions = async (data) => {
    const token = localStorage.getItem("authToken");
   const response = await skillsBasedJobsServices.post("skill/api/v1/autocomplete/Map%20Database", data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
  return response.data;
};

export const getCategorylist = async () => {
    const token = localStorage.getItem("authToken");
   const response = await skillsBasedJobsServices.get("skill/api/v1/get/all/JDCategory?language=EN-US", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
  return response.data;
};
export const getExperiencelist = async () => {
    const token = localStorage.getItem("authToken");
   const response = await skillsBasedJobsServices.get("skill/api/v1/get/all/JD ExpLevel?language=EN-US", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
  return response.data;
};

export default skillsBasedJobsServices;
