import React from "react";

const PageUp = () => {
  const handleScrollToTop = () => {
    const startPosition = window.scrollY;
    const duration = 1000;
    const startTime = performance.now();

    const scrollAnimation = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);

      /* QUADRATIC EASING FUNC TO SLOW START */
      const easing =
        progress < 0.5
          ? 2 * progress * progress
          : -1 + (4 - 2 * progress) * progress;

      window.scrollTo(0, startPosition * (1 - easing));

      if (progress < 1) {
        requestAnimationFrame(scrollAnimation);
      }
    };

    requestAnimationFrame(scrollAnimation);
  };
  return (
    <div
      className="scroll-to-top1"
      onClick={handleScrollToTop}
    >
      <span className="fa fa-angle-up"></span>
    </div>
  );
};

export default PageUp;
