import React from "react";
import { Link, NavLink } from "react-router-dom";
import navlinks from "../../../utils/models/navlinks";
import "./MobileNav.css";

const MobileNav = ({ isOpen, handleMobileMenuClose, handleFindJobsClick }) => {
  return (
    <div
      className={`mobile-nav1 ${isOpen ? "show1" : ""}`}
      onClick={handleMobileMenuClose}
    >
      <div className="navhead">Menu</div>
      <hr />
      <div className="content-container">
        <div className="nav-content1">
          <ul>
            {navlinks.map((nav, index) => (
              <li key={index}>
                <NavLink 
                  to={nav.path}
                  className={({ isActive }) =>
                    isActive ? "nav-link1 active" : "nav-link1"
                  }
                  onClick={() => handleFindJobsClick(nav?.action)}
                >
                  {nav.page}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>

        {/* JOB POST BUTTON */}
        <div className="d-flex justify-content-center jp-btn-container">
          <Link
            to="/"
            className="btn btn-primary btn-lg btn-block cursor-pointer jp-btn"
          >
            Job Post
          </Link>
        </div>

        {/* CONTACT DETAI */}
        <div className="mobile-menu-contact">
          <div className="fs-5">Call us</div>
          <div className="text-white fs-6 mt-1 mb-2">123 456 7890 329</div>
          <p>Queensberry Street, North Melbourne VIC 3051, Australia.</p>
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
