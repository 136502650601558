import React, { memo, useCallback, useEffect, useState } from "react";
import MobileNav from "../../atoms/mobile navigation/MobileNav";
import HeaderMainBox from "../../molecules/main box/HeaderMainBox";
import MobileHeader from "../../molecules/mobile header/MobileHeader";
import Popup from "../../organisms/popup/Popup";
import { useDispatch, useSelector } from "react-redux";
import { setExternelsite } from "../../../redux/features/job list/jobListSlice";

const Header = () => {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.job);
  const { externelsite } = useSelector((state) => state.jobs);

  const dataToSend = { externalSite: externelsite, jdId: id };

  const searchParams = new URLSearchParams(dataToSend).toString();

  const [isStickyHeader, setIsStickyHeader] = useState(false);
  const [showLoginPopUp, setShowLoginPopup] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const url = window.location.href;

    let siteName = "";

    if (url.includes("bluecollar") || url.includes("localhost:3001")) {
      siteName = "bluecollar";
    } else if (url.includes("whitecollar")) {
      siteName = "White Collar Jobs";
    } else if (url.includes("nursing")) {
      siteName = "Nursing Jobs";
    }
    dispatch(setExternelsite(siteName));
  }, []);
  /* HANDLE SHOW LOGIN */
  const handleShowLogin = useCallback(() => {
    //  setShowLoginPopup(true);
    // Navigate to the external URL
    window.location.href = `https://parent.dev.myskillstree.in/#/skillowner/user-auth?${searchParams}`;
  }, []);

  /* HANDLE LOGIN CLOSE */
  const handleLoginClose = useCallback(() => {
    setShowLoginPopup(false);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsStickyHeader(true);
      } else {
        setIsStickyHeader(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  /* TOGGLE MOBILE MENU OPEN */
  const handleToggleMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }, [isMobileMenuOpen]);

  /* HANDLE FIND JOBS CLICK */
  const handleFindJobsClick = useCallback(
    (action) => {
      if (action) {
        dispatch(action(false));
      }
    },
    [dispatch]
  );

  return (
    <>
      <header
        className={`main-header ${isStickyHeader ? "sticky-header1" : ""}`}
      >
        <HeaderMainBox
          handleShowLogin={handleShowLogin}
          handleFindJobsClick={handleFindJobsClick}
        />
        <MobileHeader
          isOpen={isMobileMenuOpen}
          handleShowLogin={handleShowLogin}
          handleMobileMenuOpen={handleToggleMobileMenu}
        />
      </header>
      {isMobileMenuOpen && (
        <MobileNav
          isOpen={isMobileMenuOpen}
          handleMobileMenuClose={handleToggleMobileMenu}
          handleFindJobsClick={handleFindJobsClick}
        />
      )}
      <Popup show={showLoginPopUp} onHide={handleLoginClose} />
    </>
  );
};

export default memo(Header);
