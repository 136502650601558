import { createAsyncThunk } from "@reduxjs/toolkit";
import skillsBasedJobsServices from "../../../services/skillsBasedJobsServices";

export const fetchJob = createAsyncThunk('job/fetchJob', async (id, { rejectWithValue }) => {
    try {
        const res = await skillsBasedJobsServices.get(`/skill/api/v1/skills/getJds/${id}`);
        return res?.data;
    } catch (error) {
        return rejectWithValue(error?.response?.data);
    }
});