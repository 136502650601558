import { createSlice } from "@reduxjs/toolkit";
import { fetchJob } from "./jobActions";

const initialState = {
    job: null,
    isJobLoading: 'idle',
    jobError: null
};

export const jobSlice = createSlice({
    name: 'job',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchJob.pending, (state) => {
                state.isJobLoading = 'loading';
                state.jobError = null;
            })
            .addCase(fetchJob.fulfilled, (state, action) => {
                state.isJobLoading = 'success'
                state.job = action.payload;
            })
            .addCase(fetchJob.rejected, (state, action) => {
                state.job = null;
                state.isJobLoading = 'failed';
                state.jobError = action.payload;
            });
    }
});

export default jobSlice.reducer;