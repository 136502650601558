import { setIsQuerySearch } from "../../redux/features/job list/jobListSlice";

const navlinks = [
  {
    path: "/",
    page: "Home",
  },
  {
    path: "/job-list",
    page: "Find Jobs",
    action: setIsQuerySearch
  },
  {
    path: "/about",
    page: "About",
  },
  {
    path: "/blog",
    page: "Blog",
  },
  {
    path: "/contact",
    page: "Contact",
  },
];

export default navlinks;
