const messages = {
    GET: {
        success: 'Data fetched successfully',
        error: 'Error fetching data'
    },
    POST: {
        success: 'Data uploaded successfully',
        error: 'Error uploading data'
    },
    PUT: {
        success: 'Data updated successfully',
        error: 'Error updating data'
    }
};

export default messages;