import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageUp from "../../atoms/page up/PageUp";

const Footer = () => {
  const [showPageUp, setShowPageUp] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowPageUp(true);
      } else {
        setShowPageUp(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    /* REMOVE WHEN UNMOUNT */
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <footer className="main-footer alternate">
      <div className="auto-container">
        <div className="widgets-section">
          <div className="row">
            <div className="big-column col-xl-4 col-lg-3 col-md-12">
              <div className="footer-column about-widget">
                <div className="logo">
                  <Link to="/">
                    <img src="images/logo.svg" alt="" />
                  </Link>
                </div>
                <p className="phone-num">
                  <span>Call us </span>
                  <a href="thebeehost@support.com">123 456 7890</a>
                </p>
                <p className="address">
                  329 Queensberry Street, North Melbourne VIC
                  <br /> 3051, Australia. <br />
                  <a href="mailto:support@superio.com" className="email">
                    support@superio.com
                  </a>
                </p>
              </div>
            </div>

            <div className="big-column col-xl-8 col-lg-9 col-md-12">
              <div className="row">
                <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h4 className="widget-title">For Candidates</h4>
                    <div className="widget-content">
                      <ul className="list">
                        <li>
                          <a href="#">Browse Jobs</a>
                        </li>
                        <li>
                          <a href="#">Browse Categories</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h4 className="widget-title">For Employers</h4>
                    <div className="widget-content">
                      <ul className="list">
                        <li>
                          <a href="#">Browse Candidates</a>
                        </li>
                        <li>
                          <a href="#">Employer Dashboard</a>
                        </li>
                        <li>
                          <a href="#">Add Job</a>
                        </li>
                        <li>
                          <a href="#">Job Packages</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}

                <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h4 className="widget-title">About Us</h4>
                    <div className="widget-content">
                      <ul className="list">
                        <li>
                          <a href="/#/about">About</a>
                        </li>
                        <li>
                          <a href="/#/blog">Blog</a>
                        </li>
                        <li>
                          <a href="/#/contact">Contact</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h4 className="widget-title">Helpful Resources</h4>
                    <div className="widget-content">
                      <ul className="list">
                        <li>
                          <a href="#">Site Map</a>
                        </li>
                        <li>
                          <a href="#">Terms of Use</a>
                        </li>
                        <li>
                          <a href="#">Privacy Center</a>
                        </li>
                        <li>
                          <a href="#">Security Center</a>
                        </li>
                        <li>
                          <a href="#">Accessibility Center</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="auto-container">
          <div className="outer-box">
            <div className="copyright-text">
              &copy; 2024 <a href="#">Skillsbasedjobs</a>. All Right Reserved.
            </div>
            <div className="social-links">
              {/* <a href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin-in"></i>
              </a> */}
            </div>
          </div>
        </div>
      </div>

      {/* NAVIGATE TO TOP */}
      {showPageUp && <PageUp />}
    </footer>
  );
};

export default memo(Footer);
