import { createSlice } from "@reduxjs/toolkit";
import { fetchJobList } from "./jobListActions";

const initialState = {
    jobs: [],
    formQueryData: { title: '', jobLocations: [] }, 
    isQuerySearch: false,
    isJobListLoading: 'idle',
    jobListError: null,
    filteredCategory: "",
    externelsite:""
};

export const jobListSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {
        setIsQuerySearch: (state, action) => {
            state.isQuerySearch = action.payload;
        },
        setFormQueryData: (state, action) => {
            state.formQueryData = action.payload;
        },
        setFilteredCategory: (state, action) => {
            state.filteredCategory = action.payload;
        },
         setExternelsite: (state, action) => {
            state.externelsite = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchJobList.pending, (state) => {
                state.isJobListLoading = 'loading';
                state.jobListError = null;
            })
            .addCase(fetchJobList.fulfilled, (state, action) => {
                state.isJobListLoading = 'success'
                state.jobs = action.payload;
            })
            .addCase(fetchJobList.rejected, (state, action) => {
                state.jobs = [];
                state.isJobListLoading = 'failed';
                state.jobListError = action.payload;
            }); 
    }
});

export const { setIsQuerySearch, setFormQueryData,setFilteredCategory,setExternelsite } = jobListSlice.actions;
export default jobListSlice.reducer;