import React, { createContext, useContext, useState } from "react";
import Snackbar from "../components/atoms/snackbar/Snackbar";

const SnackbarContext = createContext();

export const useSnackbarContext = () => {
    return useContext(SnackbarContext);
};

export const SnackbarProvider = ({ children }) => {
    const [snackbar, setSnackbar] = useState({
        message: "",
        visible: false,
        type: "success",
    });

    const showSnackbar = (message, type = "success", duration = 5000) => {
        setSnackbar({ message, visible: true, type });
        setTimeout(() => {
            setSnackbar({ message: "", visible: false, type: "success" });
        }, duration);
    };

    return (
        <SnackbarContext.Provider value={showSnackbar}>
            {children}
            {snackbar.visible && (
                <Snackbar
                    message={snackbar.message}
                    type={snackbar.type}
                    onClose={() => setSnackbar({ ...snackbar, visible: false })}
                />
            )}
        </SnackbarContext.Provider>
    );
};
