import React, { useEffect, useState } from "react";

const Snackbar = ({ message, type = "success", duration = 5000, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      if (onClose) {
        onClose();
      }
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  const handleClose = () => {
    setVisible(false);
    if (onClose) {
      onClose();
    }
  };

  if (!visible) return null;

  /* WE HAVE SNACKBAR CLASSES LIKE info, error, warning, success */
  const snackbarClass = `snackbar ${type}`;

  return (
    <div className={snackbarClass}>
      <p>{message}</p>
      <button className="close-btn" onClick={handleClose}>
        <span className="close_icon"></span>
      </button>
    </div>
  );
};

export default Snackbar;
