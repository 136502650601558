import { combineReducers } from "@reduxjs/toolkit";
import jobReducer from './features/job/jobSlice';
import jobListReducer from './features/job list/jobListSlice';
import recentJobsReducer from './features/job list/recentJobListSlice';

const rootReducer = combineReducers({
    job: jobReducer,
    jobs: jobListReducer,
    recentJobs: recentJobsReducer
});

export default rootReducer;