import React, { memo } from "react";
import { Modal } from "react-bootstrap";

const Popup = ({ show, onHide, children }) => {
  return (
    <Modal show={show} onHide={onHide} backdrop="static" centered>
      <Modal.Header
        closeButton
        className="border border-bottom-0"
      ></Modal.Header>
      <div id="login-modal">
        <div className="login-form default-form">
          <div className="form-inner">
            <h3>Login to Skills based jobs</h3>
            <form method="post" action="add-parcel.html">
              <div className="form-group">
                <label>Username</label>
                <input
                  type="text"
                  name="username"
                  placeholder="Username"
                  required
                />
              </div>

              <div className="form-group">
                <label>Password</label>
                <input
                  id="password-field"
                  type="password"
                  name="password"
                  value=""
                  placeholder="Password"
                />
              </div>

              <div className="form-group">
                <div className="field-outer">
                  <div className="input-group checkboxes square">
                    <input
                      type="checkbox"
                      name="remember-me"
                      value=""
                      id="remember"
                    />
                    <label htmlFor="remember" className="remember">
                      <span className="custom-checkbox"></span> Remember me
                    </label>
                  </div>
                  <a href="#" className="pwd">
                    Forgot password?
                  </a>
                </div>
              </div>

              <div className="form-group">
                <button
                  className="theme-btn btn-style-one"
                  type="submit"
                  name="log-in"
                >
                  Log In
                </button>
              </div>
            </form>

            <div className="bottom-box">
              <div className="text">
                Don't have an account?{" "}
                <a href="register-popup.html" className="call-modal signup">
                  Signup
                </a>
              </div>
              <div className="divider">
                <span>or</span>
              </div>
              <div className="btn-box row">
                <div className="col-lg-6 col-md-12">
                  <a href="#" className="theme-btn social-btn-two facebook-btn">
                    <i className="fab fa-facebook-f"></i> Log In via Facebook
                  </a>
                </div>
                <div className="col-lg-6 col-md-12">
                  <a href="#" className="theme-btn social-btn-two google-btn">
                    <i className="fab fa-google"></i> Log In via Gmail
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default memo(Popup);
