import "./App.css";
import AppRouter from "./routes/AppRouter";

function App() {
  const location = window.location;

  if (location.hostname !== "localhost") {
    console.log = () => {};
    console.info = () => {};
    console.error = () => {};
    console.warn = () => {};
    console.assert = () => {};
    console.table = () => {};
    console.debug = () => {};
  }

  return <AppRouter />;
}

export default App;
 