import aboutRoutes from "../components/pages/about/aboutRoutes";
import blogRoutes from "../components/pages/blog/blogRoutes";
import contactRoutes from "../components/pages/contact/contactRoutes";
import homeRoutes from "../components/pages/home/homeRoutes";
import jobDataRooutes from "../components/pages/job data/jobDataRoutes";
import jobListRoutes from "../components/pages/job list/jobListRoutes";

const routes = [
  ...homeRoutes,
  ...jobListRoutes,
  ...jobDataRooutes,
  ...aboutRoutes,
  ...blogRoutes,
  ...contactRoutes
];

export default routes;
