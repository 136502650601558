import { lazy } from "react";
import MainLayout from "../../layout/main/MainLayout";

const contactRoutes = [
  {
    path: "/contact",
    element: lazy(() => import("./Contact")),
    layout: MainLayout,
  },
];

export default contactRoutes;
