
const apiConfig = {
    development: {
        baseURL: "https://beanstalk.myskillstree.in"
    },
    production: {
        baseURL: "https://beanstalk.myskillstree.in"
    },
    staging: {
        baseURL: "https://beanstalk.myskillstree.in"
    },
};

export const getApiBaseURL = () => {
    const environment = 'development';
    return apiConfig[environment].baseURL;
};