import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { AiOutlineWarning } from "react-icons/ai";

const SomethingWentWrong = ({ message = "Something Went Wrong..." }) => {
  /* REFRESH */
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh", backgroundColor: "#e9ecef" }}
    >
      <Row
        className="text-center"
        style={{
          width: "100%",
          maxWidth: "600px",
          borderRadius: "12px",
          backgroundColor: "#fff",
          padding: "40px",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Col>
          <div className="mb-4" style={{ fontSize: "3rem", color: "#dc3545" }}>
            <AiOutlineWarning />
          </div>
          <h2 style={{ fontWeight: "bold", color: "#343a40" }}>{message}</h2>
          <p className="text-muted" style={{ fontSize: "1.1rem" }}>
            Please try refreshing the page or check back later.
          </p>
          <Button
            variant="danger"
            onClick={handleRefresh}
            className="mt-3"
            style={{
              padding: "10px 20px",
              fontSize: "1rem",
              borderRadius: "20px",
            }}
          >
            Refresh Page
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SomethingWentWrong;
