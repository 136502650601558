import { lazy } from "react";
import MainLayout from "../../layout/main/MainLayout";

const aboutRoutes = [
  {
    path: "/about",
    element: lazy(() => import("./About")),
    layout: MainLayout,
  },
];

export default aboutRoutes;
