import { lazy } from "react";
import MainLayout from "../../layout/main/MainLayout";

const blogRoutes = [
  {
    path: "/blog",
    element: lazy(() => import("./Blogs")),
    layout: MainLayout,
  },
  {
    path: "/blog/:id",
    element: lazy(() => import("./BlogSinglePage")),
    layout: MainLayout,
  },
];

export default blogRoutes;
